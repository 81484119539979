import React from "react";
import styled from "styled-components";
import {
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
  TextEditor,
} from "../common";
import { toHTML } from "../../utils/helpers/index";

const FaqPageList = styled.ul`
  list-style: none;
  max-width: 980px;
`;

const FaqPageListItem = styled.li`
  margin-bottom: 24px;

  h2 {
    margin-bottom: 16px;
    font-size: 22px;
    color: black;
  }
`;

export const FaqPageQuestions = ({ faqList }) => {
  return (
    <PageSection bgColor="var(--creamWhite)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <PageSectionArticle>
          <FaqPageList>
            {!!faqList &&
              faqList.map(({ question, body }, i) => (
                <FaqPageListItem key={i}>
                  <h2>{question}</h2>
                  <TextEditor
                    dangerouslySetInnerHTML={{ __html: toHTML(body) }}
                  />
                </FaqPageListItem>
              ))}
          </FaqPageList>
        </PageSectionArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
