import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { PageSectionWrapper } from "../components/common";
import { FaqPageHeader, FaqPageQuestions } from "../components/FaqPage";

export const FaqPageTemplate = ({ title, faqPage }) => {
  const { heading, preamble, faqList } = faqPage;
  return (
    <>
      <SEO title={title} />
      <PageSectionWrapper>
        <FaqPageHeader heading={heading} preamble={preamble} />
        <FaqPageQuestions faqList={faqList} />
      </PageSectionWrapper>
    </>
  );
};

const FaqPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <FaqPageTemplate title={frontmatter.title} faqPage={frontmatter.faqPage} />
  );
};

export default FaqPage;

export const faqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        faqPage {
          heading
          preamble
          faqList {
            question
            body
          }
        }
      }
    }
  }
`;
