import React from "react";
import {
  PageSection,
  PageSectionBackdrop,
  PageSectionHeader,
  PageSectionHeaderText,
} from "../common";
import { PageHeaderImage } from "../common/PageHeaderImage";

export const FaqPageHeader = ({ heading, preamble }) => {
  return (
    <PageSection bgColor="var(--creamWhite)">
      <PageSectionBackdrop bgColor="var(--creamWhite)">
        <PageSectionHeader>
          <PageHeaderImage />
          <PageSectionHeaderText>
            <h1>{heading}</h1>
            <p>{preamble}</p>
          </PageSectionHeaderText>
        </PageSectionHeader>
      </PageSectionBackdrop>
    </PageSection>
  );
};
